import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as Actions from '../state/app'

const Container = styled.div`
  width: 100vw;
`

export class Base extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false
    }
  }

  render() {
    return (
      <Container>
        <h1>Base</h1>
      </Container>
    )
  }
}

function mapStateToProps({ displayNav }) {
  return { displayNav }
}

function mapDispatchToProps(dispatch) {
  return {
    toggle: () => { dispatch(Actions.toggleNav()) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Base)
